/*------------------------------------*\
    #TYPOGRAPHY_BASE
\*------------------------------------*/
@import 'settings.typography';

html {
  font-family: 'Open Sans', 'Tahoma', 'Geneva', 'sans-serif';
  font-size: 62.5%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $--header-font-weight;
}

body {
  font-size: 1.6rem;
}

h3 {
  font-size: 2.1rem;
}
