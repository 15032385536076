@import 'settings';
@import 'settings.colors';

html {
  font-size: $--base-font-size;
}

body {
  background-color: #e8eaee;
}

ul,
ol {
  list-style: none;
}

a,
a:visited {
  text-decoration: none;
  /*color: inherit;*/
}

p {
  margin: 2% 0;
}

i {
  // margin-right: 0.4rem;
}
