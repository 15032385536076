@import 'generic.reset';
@import 'generic.normalize';
@import 'generic.box-sizing';
@import 'generic.placeholder';
@import 'generic.preloader';

@import 'personal-address-modal';
@import 'base.elements';
@import 'base.typography';

.Toastify__toast-body {
  word-break: 'break-word';
}
