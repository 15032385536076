/*------------------------------------*\
    #TYPOGRAPHY_SETTINGS
\*------------------------------------*/

$--light: 300; // light
$--normal: 400; // normal
$--semi-bold: 600; // semi-bold
$--bold: 700; // bold
$--extra-bold: 800; // extra-bold

$--mono-font-family: 'Open Sans', Tahoma, Geneva, sans-serif;

$--base-font-family: $--mono-font-family;
$--base-font-weight: var(--light);

$--header-font-family: $--mono-font-family;
$--header-font-weight: var(--extra-bold);

@font-face {
  font-family: 'Pureprofile';
  src:
    url('../../survey-tool/styles/fonts/pureprofile.ttf?9llwc0') format('truetype'),
    url('../../survey-tool/styles/fonts/pureprofile.woff?9llwc0') format('woff'),
    url('../../survey-tool/styles/fonts/pureprofile.svg?9llwc0#Pureprofile') format('svg');
}
