.buttonDone {
  height: 36px !important;
  width: 300px !important;
  color: #333333 !important;
  font-size: 14px !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600 !important;
  text-align: center !important;
  letter-spacing: 0.2px !important;
  text-transform: none !important;
  background: #2bd5c8 !important;
  border: none !important;
  border-radius: 4px !important;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.23) !important;
  padding: 0 16px !important;
  margin: 10px 0 5px !important;
}

.buttonDone:hover {
  background-color: #5cdfd5 !important;
}

.buttonDone:focus {
  background-color: #5cdfd5 !important;
  box-shadow:
    inset 0 -1px 0 0 rgba(0, 0, 0, 0.23),
    0 0 0 2px #4169e1 !important;
  outline: none;
}
