.error-container {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-message {
  position: relative;
  min-height: 75px;
  text-align: center;
  border-radius: 0.5rem;
  padding: 1rem;
  box-sizing: border-box;
  animation: fadein 0.7s;
}

.error-message-text {
  display: inline-block;
  font-family: 'Open Sans', Arial, Verdana, sans-serif;
  margin-left: 3rem;
  font-size: 1rem;
}

.error-indicator {
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.path {
  fill: none;
  stroke-width: 6px;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.path-1 {
  stroke-dasharray: 260 1000;
  stroke-dashoffset: 261;
  animation: dash 1s linear forwards;
}
.path-2 {
  stroke-dasharray: 260 1000;
  stroke-dashoffset: 261;
  animation: dash 1s linear forwards;
}
.path-3 {
  stroke-dasharray: 260 1000;
  stroke-dashoffset: 261;
  animation: dash 1s linear forwards;
}
.path-4 {
  stroke-dasharray: 260 1000;
  stroke-dashoffset: 261;
  animation: dash 1s linear forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
